.plans-header {
    text-align: center;
}

.plan-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}
