.extra-grid-wrapper {
    display: flex;
    justify-content: center;
}

.extras-label {
    text-align: center;
}

.extras-list {
    list-style: none;
    padding-left: 0;

    li:not(:last-child) {
        margin-bottom: 1rem;
    }
}

.payment-plan-list {
    margin-top: 0;
    margin-bottom: 0;
}