.sa-select-account-btn-wrapper {
  margin-top: 1rem;
  display: flex;
	width: 50%;
  justify-content: right;
}

.sa-create-account-btn-wrapper {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.sa-renewal-toggle-wrapper {
	margin-top: 1rem;
	width: 50%;
}

.sa-select-area-wrapper {
	display: flex;	
}
