@media (max-width:728px) {
    .plan-box {
        max-width: 100%;
    }
}

.plan-box {
    display: inline-block;
    width: 100%;
    max-width: 350px;
    font-family: 'Source Sans Pro', sans-serif;
    border: 3px solid;
    font-size: 1rem;
    background: #FFF;
    margin: 10px;
    vertical-align: text-top;
    border-radius: 10px
}

.bdr-wdf {
    border-color: #447ABD;
}

.bdr-jtb {
    border-color: #56BA60;
}

.bdr-ls {
    border-color: #F16754;
}

.bdr-pp {
    border-color: #85439A;
}

.bdr-misc {
    border-color: #002B5C;
}

.burke-plan-component {
    a {
        text-decoration: none;
    }

    .fa-check {
        content: "\f00c";
    }

    .fa-check-circle-o {
        content: "/f05d"
    }

    .fa-star {
        content: "\f005";
    }

    .plan-box-header {
        text-align: center;
        color: #fff;
        min-height: 101px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .plan-box-title {
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        font-weight: 300;
        font-size: 2.2rem;
        padding: 12px;
    }

    .plan-box-title span {
        display: block;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 0.95rem;
        line-height: 1;
        background-color: #FFD457;
        padding: 5px 10px;
        border-radius: 50px;
        min-width: 196px;
        color: #002B5C;
    }

    .plan-box-body {
        margin: 10px 15px 20px 5px;
    }

    .plan-box-list ul,
    li {
        list-style: none;
    }

    .plan-box-list li {
        padding: 0px 0px 10px;
    }

    .plan-box-list li:before {
        content: '\f05d';
        font-family: 'FontAwesome';
        float: left;
        margin-left: -1.5em;
        position: relative;
        top: 3px;
    }

    .plan-box-price {
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        font-weight: 500;
        font-size: 3rem;
        text-align: center;
        line-height: 1;
    }

    .plan-box-price span {
        font-size: 1.5rem;
        position: relative;
        bottom: 20px;
        padding-right: 2px;
    }

    .plan-box-sub-txt {
        background-color: #fff;
        color: #808285;
        text-align: center;
        font-size: 0.9rem !important;
        font-style: italic;
        padding: 0px !important;
    }

    .plan-box-btn {
        text-align: center;
        text-transform: uppercase;
        font-family: 'Oswald', sans-serif;
        font-weight: 400;
        border: 3px solid;
        padding: 10px;
        border-radius: 50px;
        margin: 20px 50px 35px;
        font-size: 1.1rem;
        letter-spacing: 0.75px;
        transition: 0.2s;
        background-color: transparent;
    }

    .wdf-plan-box-btn:hover {
        border-color: #447ABD;
        color: #FFFFFF;
        background: #447ABD;
    }

    .jtb-plan-box-btn:hover {
        border-color: #56BA60;
        color: #FFFFFF;
        background: #56BA60;
    }

    .ls-plan-box-btn:hover {
        border-color: #F16754;
        color: #FFFFFF;
        background: #F16754;
    }

    .pp-plan-box-btn:hover {
        border-color: #85439A;
        color: #FFFFFF;
        background: #85439A;
    }

    .wdf,
    .wdf-list li:before {
        color: #447ABD;
    }

    .bg-wdf {
        background-color: #447ABD;
    }

    .wdf-btn {
        border-color: #447ABD;
        color: #447ABD;
    }

    .jtb,
    .jtb-list li:before {
        color: #56BA60;
    }

    .bg-jtb {
        background-color: #56BA60;
    }

    .jtb-btn {
        border-color: #56BA60;
        color: #56BA60;
    }

    .ls,
    .ls-list li:before {
        color: #F16754;
    }

    .bg-ls {
        background-color: #F16754;
    }

    .ls-btn {
        border-color: #F16754;
        color: #F16754;
    }

    .pp,
    .pp-list li:before {
        color: #85439A;
    }

    .bg-pp {
        background-color: #85439A;
    }

    .pp-btn {
        border-color: #85439A;
        color: #85439A;
    }

    .misc,
    .misc-list li:before {
        color: #002B5C
    }

    .bg-misc {
        background-color: #002B5C;
    }

    .misc-btn {
        border-color: #002B5C;
        color: #002B5C;
    }

    .plan-x {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 28px
    }

    .plan-x img {
        width: 70px;
        position: absolute;
        z-index: 1;
    }

    .plan-price-small {
        font-size: 2.5rem;
    }

    .plan-price-small span {
        font-size: 1rem;
    }
}

.select-plan-btn-wrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}